.product-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    
}

.product-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 1em;
    height: 100%;
    padding: 50px;
}

.product-img{
    height: 300px;
}

