#section-history{
    background-image: url("../../public/picture-history.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.col-history-content{
    padding: 2em;
    background-color: var(--accent);
    width: 50%;
}

.history-container{
    display: flex;
    flex-direction: row;
}

/************** MEDIA QUERIES ****************/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 780px) {
    .col-history-content{
        width: 100%;
        padding: 1em;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}