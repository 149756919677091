#section-mission{
    /* flex-direction: column;
    
    width: 100%; */
    background-color: var(--light-bg);
}

.mission-container{
    display: flex;
    flex-direction: row;
}

.col-mission-img{
    width: 50%;
}

/************** MEDIA QUERIES ****************/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mission-container{
        flex-direction: column;
        align-items: center;
    }

    .col-mission-img{
        width:100%;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}